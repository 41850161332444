import { usePageContext } from "vike-react/usePageContext";
import { locales } from "./constants";
import { getCookie } from "../../../libraries/cookies";
import { translations } from "./translations";

export const t = (key, locale, gameSlug) => {
  if (!locale) locale = usePageContext()?.locale;

  const isClient = typeof window !== "undefined";

  if (isClient && !locale) {
    const cookieLang = getCookie("gamly-lang");
    locale = cookieLang;

    if (!locale) locale = navigator?.language || navigator?.userLanguage;
  }

  if (!locale || !locales.some((l) => l.code === locale)) locale = localeDefault.code;

  const textTranslations = translations[locale];
  if (!textTranslations) return key;

  return textTranslations[`${gameSlug}-${key}`] || textTranslations[key] || key;
};
